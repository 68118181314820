<template>
  <Navigation index="attachment" ref="navigation">
    <div style="height: 100%">
      <div class="topnavigation">
        <el-button
          type="primary"
          @click="OpenAttachment({ Price: 0, TaxRate: 10, Description: '' })"
          >{{ tools.GetLanguageValue("web.新增") }}
        </el-button>
      </div>

      <div style="height: calc(100% - 85px)">
        <el-table
          border
          :data="AttachmentDatas"
          style="width: 100%"
          :height="ContentHeight + 'px'"
        >
          <el-table-column type="index" width="50"> </el-table-column>

          <el-table-column
            :label="tools.GetLanguageValue('web.配件名称')"
            width="180"
            prop="Name"
          />
          <el-table-column
            :label="tools.GetLanguageValue('web.助记码')"
            width="180"
            prop="MnemonicCode"
          />
          <!-- <el-table-column
            :label="tools.GetLanguageValue('web.图片')"
            width="100"
          >
            <template #default="scope">
              <div style="display: flex; align-items: center">
                <el-image
                  width="60"
                  height="60"
                  :src="scope.row.ImagesFormat"
                  class="avatar"
                />
              </div>
            </template>
          </el-table-column> -->
          <el-table-column
            :label="tools.GetLanguageValue('web.价格')"
            width="120"
            prop="Price"
          />
          <!-- <el-table-column
            :label="tools.GetLanguageValue('web.税率')"
            width="120"
            prop="TaxRate"
          /> -->
          <el-table-column
            :label="tools.GetLanguageValue('web.库存')"
            width="120"
            prop="StockCount"
          />

          <el-table-column
            :label="tools.GetLanguageValue('web.操作')"
            fixed="right"
            width="260"
          >
            <template #default="scope">
              <el-button
                type="success"
                size="small"
                @click="OpenAttachment(scope.row)"
              >
                {{ tools.GetLanguageValue("web.编辑") }}</el-button
              >
              <el-button
                type="primary"
                size="small"
                @click="OpenInsertAttachmentStock(scope.row)"
              >
                {{ tools.GetLanguageValue("web.入库") }}</el-button
              >
              <el-button
                size="small"
                @click="DeleteAttachment(scope.row)"
                type="danger"
              >
                {{ tools.GetLanguageValue("web.删除") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div style="padding: 5px">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="GetAttachment"
            :page-size="AttachmentPadding.Size"
            :total="AttachmentPadding.Count"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </Navigation>

  <!-- 添加 -->
  <el-dialog
    v-model="OpenAttachmentWindow"
    :title="tools.GetLanguageValue('web.编辑配件')"
    width="800px"
    :show-close="false"
  >
    <el-tabs v-model="LanguageName">
      <el-tab-pane :label="tools.GetLanguageValue('web.默认')" name="first">
        <el-form
      ref="form"
      :model="AttachmentOpt"
      label-width="130px"
      style="margin-top: 15px"
    >
      <el-form-item :label="tools.GetLanguageValue('web.配件名称')">
        <el-input
        :placeholder=" tools.GetLanguageValue('web.请输入配件名称')"
          v-model="AttachmentOpt.Name"
          @focus="InputSelect($event)"
        ></el-input>
      </el-form-item>
      <el-form-item :label="tools.GetLanguageValue('web.助记码')">
        <el-input
          v-model="AttachmentOpt.MnemonicCode"
          :placeholder=" tools.GetLanguageValue('web.请输入助记码')"
          @focus="InputSelect($event)"
        ></el-input>
      </el-form-item>

      <el-form-item :label="tools.GetLanguageValue('web.配件价格')">
        <el-input-number
          @focus="InputSelect($event)"
          v-model="AttachmentOpt.Price"
          :min="0"
          :max="100000"
        ></el-input-number>
      </el-form-item>
      <!-- <el-form-item :label="tools.GetLanguageValue('web.配件税率')">
        <el-input-number
          @focus="InputSelect($event)"
          v-model="AttachmentOpt.TaxRate"
          :min="0"
          :max="100"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="tools.GetLanguageValue('web.是否含税')">
        <el-switch
          v-model="AttachmentOpt.IncludeTax"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item> -->
      <el-form-item :label="tools.GetLanguageValue('web.备注')">
        <el-input
          :placeholder="tools.GetLanguageValue('web.请输入备注')"
          v-model="AttachmentOpt.Description"
          @focus="InputSelect($event)"
        ></el-input>
      </el-form-item>

      <!-- <el-form-item :label="tools.GetLanguageValue('web.封面图')">
        <el-upload
          class="avatar-uploader"
          :action="UploadPictureImageUrl"
          :show-file-list="false"
          :on-success="UploadSuccess"
          :before-upload="UploadSuccess"
          :headers="headersImage"
        >
          <img
            v-if="AttachmentOpt.ImagesFormat"
            :src="AttachmentOpt.ImagesFormat"
            class="avatar"
          />
          <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
      </el-form-item> -->
    </el-form>

      </el-tab-pane>
      <el-tab-pane
          :label="item.LanguageName"
          :name="item.LanguageName"
          v-for="(item, index) in AttachmentOpt.Languages"
          :key="index"
        >
          <el-form-item :label="tools.GetLanguageValue('web.商品名称')">
            <el-input
              v-model="item.Language.Name"
              @focus="InputSelect"
            ></el-input>
          </el-form-item>

        
        </el-tab-pane>
    </el-tabs>

 
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="OpenAttachmentWindow = false">
          {{ tools.GetLanguageValue("web.关闭") }}</el-button
        >
        <el-button type="primary" @click="AddAttachment">
          {{ tools.GetLanguageValue("web.保存") }}</el-button
        >
      </span>
    </template>
  </el-dialog>

  <!-- 库存 -->
  <el-dialog
    v-model="OpenAttachmentStockWindow"
    :title="tools.GetLanguageValue('web.增加库存')"
    width="700px"
    :show-close="false"
  >
    <el-form
      ref="form"
      :model="AttachmentOpt"
      label-width="80px"
      style="margin-top: 15px"
    >
      <el-form-item :label="tools.GetLanguageValue('web.配件名称')">
        <el-input v-model="AttachmentOpt.Name"></el-input>
      </el-form-item>
      <el-form-item :label="tools.GetLanguageValue('web.增加数量')">
        <el-input-number
          v-model="AttachmentOpt.Quantity"
          @focus="InputSelect($event)"
          ref="quantitySelect"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="tools.GetLanguageValue('web.备注')">
        <el-input v-model="AttachmentOpt.Remarks"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="OpenAttachmentStockWindow = false">
          {{ tools.GetLanguageValue("web.关闭") }}</el-button
        >
        <el-button @click="InsertAttachmentStock" type="primary">
          {{ tools.GetLanguageValue("web.保存") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
import axios from "../../../../commons/AxiosMethod.js";
import tools from "../../../../commons/tools.js";
import { CloseBold, Plus } from "@element-plus/icons-vue";
import { ElMessageBox, ElMessage } from "element-plus";
import Navigation from "../../../../components/Modules/Navigation.vue";

export default {
  data() {
    return {
      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight,

      AttachmentOpt: {},
      OpenAttachmentWindow: false,
      AttachmentDatas: [],
      AttachmentPadding: {
        Page: 1,
        Size: 30,
        Count: 0,
      },
      OpenAttachmentStockWindow: false,

      headersImage: {},
      UploadPictureImageUrl: "",
      ContentHeight: 900,
      tools: tools,

      LanguageName:'first'
    };
  },
  name: "App",
  mounted() {
    this.GetAttachment();

    this.UploadPictureImageUrl = axios.GetUrl() + "/api/Upload/ImageFileWebp";
    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header"),
    };

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
        this.ContentHeight = document.documentElement.clientHeight - 130;
        this.$refs.navigation.onresize();
      })();
    };
    this.ContentHeight = this.clientHeight - 130;
  },

  components: {
    Navigation,
    CloseBold,
    Plus,
  },
  methods: {
    // 上传成功操作
    UploadSuccess(response, file, fileList) {
      if (response.Code == 200) {
        this.ComImageUrl = response.Data.FileName;
        this.ComImageFormet = response.Data.ImageFormet;
        this.AttachmentOpt.ImagesFormat = response.Data.ImageFormet;
        this.AttachmentOpt.Images = response.Data.FileName;
      }
    },
    InputSelect(e) {
      console.log(e);
      e.currentTarget.select();
    },

    OpenInsertAttachmentStock(item) {
      this.OpenAttachmentStockWindow = true;
      this.AttachmentOpt = item;
      this.AttachmentOpt.Quantity = 0;
      // this.$nextTick(()=>{

      //   var value =  this.$ref.quantitySelect.value  //获取input的值
      //   alert(value)
      //   this.$refs.quantitySelect.setSelectionRange(0, value.length)

      // })

      // this.InputSelect(this.$refs.quantitySelect);
    },
    //入库
    InsertAttachmentStock() {
      ElMessageBox.confirm(
        "确定要入库<span style='color:red;font-size:14pt'>[" +
          this.AttachmentOpt.Name +
          "] " +
          this.AttachmentOpt.Quantity +
          "个</span>吗" +
          "?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          const param = {
            ProductId: this.AttachmentOpt.Id,
            Quantity: this.AttachmentOpt.Quantity,
            Remarks: this.AttachmentOpt.Remarks,
          };
          axios.apiMethod(
            "/stores/Storage/UpdateStockItem",
            "post",
            param,
            (result) => {
              if (result.Data.IsSuccess) {
                this.GetAttachment();
                this.OpenAttachmentStockWindow = false;
              } else {
                ElMessageBox({
                  message: result.FullMsg,
                  type: "error",
                });
              }
            }
          );
        })
        .catch(() => {
          // catch error
        });
    },
    //插入库存
    InsertStock() {
      const param = {};
      axios.apiMethod(
        "/stores/GoodsManage/UpdateStockItem",
        "get",
        { goodId: item.GoodsId },
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetAttachment();
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error",
            });
          }
        }
      );
    },
    //删除配件
    DeleteAttachment(item) {
      ElMessageBox.confirm(
        "确定要删除<span style='color:red;font-size:14pt'>[" +
          item.Name +
          "]</span>吗" +
          "?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          const param = {};
          axios.apiMethod(
            "/stores/GoodsManage/DeleteAttachment",
            "get",
            { goodId: item.GoodsId },
            (result) => {
              if (result.Data.IsSuccess) {
                this.GetAttachment();
              } else {
                ElMessageBox({
                  message: result.FullMsg,
                  type: "error",
                });
              }
            }
          );
        })
        .catch(() => {
          // catch error
        });
    },
    OpenAttachment(item) {
      const param = {};
      axios.apiMethod(
        "/stores/GoodsManage/GetAttachmentDetail",
        "get",
        {
          id: item.Id,
        },
        (result) => {
          this.OpenAttachmentWindow = true;
          this.AttachmentOpt = result.Data;
        }
      );

      // this.AttachmentOpt = item;
      // this.OpenAttachmentWindow = true;
    },
    //添加配件
    AddAttachment() {
      const param = {};
      axios.apiMethod(
        "/stores/GoodsManage/AddAttachment",
        "post",
        this.AttachmentOpt,
        (result) => {
          if (result.Data.IsSuccess) {
            this.GetAttachment();
            this.OpenAttachmentWindow = false;
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error",
            });
          }
        }
      );
    },
    //查询配件
    GetAttachment() {
      const param = {};
      axios.apiMethod(
        "/stores/GoodsManage/GetAttachment",
        "post",
        this.AttachmentPadding,
        (result) => {
          if (result.Code == 200) {
            this.AttachmentDatas = result.Data.Datas;
            this.AttachmentPadding.Page = result.Data.Page;
            this.AttachmentPadding.Size = result.Data.Size;
            this.AttachmentPadding.Count = result.Data.Count;
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error",
            });
          }
        }
      );
    },
  },
};
</script>
  
<style scoped>
.main-wrapper {
  padding: 5px;
}
</style>
  